define("additive-journal/mirage/scenarios/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.admin = _exports.manager = _exports.member = _exports.resellerAdmin = _exports.resellerManager = _exports.resellerMember = void 0;

  var resellerMember = function resellerMember(server) {
    server.get("/me", function (schema) {
      return schema.users.findBy({
        isReseller: true,
        role: 'member'
      });
    });
  };

  _exports.resellerMember = resellerMember;

  var resellerManager = function resellerManager(server) {
    server.get("/me", function (schema) {
      return schema.users.findBy({
        isReseller: true,
        role: 'manager'
      });
    });
  };

  _exports.resellerManager = resellerManager;

  var resellerAdmin = function resellerAdmin(server) {
    server.get("/me", function (schema) {
      return schema.users.findBy({
        isReseller: true,
        role: 'admin'
      });
    });
  };

  _exports.resellerAdmin = resellerAdmin;

  var member = function member(server) {
    server.get("/me", function (schema) {
      return schema.users.findBy({
        isAdditive: false,
        isReseller: false,
        role: 'member'
      });
    });
  };

  _exports.member = member;

  var manager = function manager(server) {
    server.get("/me", function (schema) {
      return schema.users.findBy({
        isAdditive: false,
        isReseller: false,
        role: 'manager'
      });
    });
  };

  _exports.manager = manager;

  var admin = function admin(server) {
    server.get("/me", function (schema) {
      return schema.users.findBy({
        isAdditive: false,
        isReseller: false,
        role: 'admin'
      });
    });
  };

  _exports.admin = admin;
});