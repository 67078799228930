define("additive-journal/models/topic", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    postCount: (0, _model.attr)('number'),
    language: (0, _model.attr)('string'),
    availableLanguages: (0, _model.attr)(),
    medium: (0, _model.attr)(),
    image: Ember.computed.alias('medium')
  });

  _exports.default = _default;
});