define("additive-journal/helpers/ui-array-includes", ["exports", "@additive-apps/ui/helpers/ui-array-includes"], function (_exports, _uiArrayIncludes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiArrayIncludes.default;
    }
  });
  Object.defineProperty(_exports, "uiArrayIncludes", {
    enumerable: true,
    get: function get() {
      return _uiArrayIncludes.uiArrayIncludes;
    }
  });
});