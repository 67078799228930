define("additive-journal/mirage/scenarios/app/default", ["exports", "additive-journal/mirage/scenarios/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var DEFAULTS = {
    contentLanguages: ['de', 'en', 'it'],
    defaultLanguage: 'de'
  };
  /**
   * server is the default scenario for normal app-use (development, staging)
   */

  function _default(server) {
    server.passthrough('https://staging.account.additive-apps.tech/**');
    server.passthrough('https://api.staging.multimedia.additive-apps.tech/**');
    server.passthrough('https://additive-helper.herokuapp.com/**');
    server.passthrough("".concat(server.urlPrefix, "/").concat(server.namespace, "/")); // TODO: remove the following endpoints once the org and me API routes are implemented

    server.create('user', {
      role: 'admin'
    });
    (0, _user.resellerAdmin)(server);
    server.get("".concat(server.urlPrefix, "/:orgSlug"), function () {
      return {
        organization: Object.assign({}, DEFAULTS, {
          planName: 'professional_yearly'
        })
      };
    });
  }
});