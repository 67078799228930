define("additive-journal/templates/instance/authors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q36nqeLm",
    "block": "{\"symbols\":[\"view\"],\"statements\":[[8,\"ui-filterable\",[],[[\"@key\",\"@modelName\"],[\"authors\",\"author\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,\"ui-content-views\",[],[[\"@title\",\"@detailRoute\",\"@filter\",\"@searchPlaceholder\",\"@theme\"],[[30,[36,0],[\"routes.authors.title\"],null],\"instance.authors.author\",\"authors\",[30,[36,0],[\"global.actions.search\"],null],\"grey\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,[32,1,[\"navbar\"]],[],[[],[]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"intro\"]],[],[[\"@class\",\"@title\",\"@size\",\"@isMultiline\"],[\"mw6\",[30,[36,0],[\"routes.authors.title\"],null],\"xl\",true]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[\"@class\"],[\"pt4\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"ui-list-view\",[],[[\"@filterKey\",\"@modelName\",\"@descriptionProperty\",\"@detailRoute\"],[\"authors\",\"author\",\"role\",\"instance.authors.author\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],[\"detail\"],null]],null]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "additive-journal/templates/instance/authors.hbs"
    }
  });

  _exports.default = _default;
});