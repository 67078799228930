define("additive-journal/mirage/config", ["exports", "additive-journal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.urlPrefix = _environment.default.APP.apiBaseHost;
    /* Allow any organization-id */

    this.namespace = '**';
  }
});