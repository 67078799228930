define("additive-journal/templates/instance/widgets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V5Gqp5Rt",
    "block": "{\"symbols\":[\"view\"],\"statements\":[[8,\"ui-content-views\",[],[[\"@title\",\"@theme\"],[[30,[36,0],[\"routes.widgets.title\"],null],\"grey\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"navbar\"]],[],[[],[]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"intro\"]],[],[[\"@class\",\"@title\",\"@size\",\"@isMultiline\"],[\"mw6\",[30,[36,0],[\"routes.widgets.title\"],null],\"xl\",true]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[],[[\"@class\"],[\"pt4\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "additive-journal/templates/instance/widgets.hbs"
    }
  });

  _exports.default = _default;
});