define("additive-journal/mirage/scenarios/test/default", ["exports", "additive-journal/mirage/scenarios/organization", "additive-journal/mirage/scenarios/user"], function (_exports, _organization, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_ORG_ID = void 0;
  var DEFAULT_ORG_ID = 'test-org';
  _exports.DEFAULT_ORG_ID = DEFAULT_ORG_ID;

  var _default = function _default(server) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var _options = Object.assign({
      loadOrganization: true,
      loadUser: true
    }, options);

    server.create('organization', {
      id: DEFAULT_ORG_ID
    });
    server.create('user');
    server.create('user', {
      role: 'manager'
    });
    server.create('user', {
      role: 'admin'
    });
    server.create('user', {
      isAdditive: false,
      isReseller: false
    });
    server.create('user', {
      isAdditive: false,
      isReseller: false,
      role: 'manager'
    });
    server.create('user', {
      isAdditive: false,
      isReseller: false,
      role: 'admin'
    });
    _options.loadOrganization && (0, _organization.starter)(server);
    _options.loadUser && (0, _user.resellerMember)(server);
  };

  _exports.default = _default;
});