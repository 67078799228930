define("additive-journal/adapters/application", ["exports", "additive-journal/config/environment", "@additive-apps/auth/adapters/application", "ember-inflector"], function (_exports, _environment, _application, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    currentUser: Ember.inject.service(),
    uiAppSettings: Ember.inject.service(),
    host: _environment.default.APP.apiBaseHost,
    currentLocale: Ember.computed.alias('uiAppSettings.currentLocale'),
    headers: Ember.computed('currentLocale', 'session.data.authenticated.access_token', {
      get: function get() {
        var headers = {
          Accept: 'application/vnd.journal.backend+json',
          'Accept-Language': this.currentLocale || 'de',
          'Content-Type': 'application/json'
        };
        return Object.assign({}, this._super('headers'), headers);
      }
    }),
    namespace: Ember.computed('currentUser.currentOrganization.id', {
      get: function get() {
        return this.currentUser.currentOrganization.id;
      }
    }),
    pathForType: function pathForType(modelName) {
      return (0, _emberInflector.pluralize)(modelName);
    }
  });

  _exports.default = _default;
});