define("additive-journal/mirage/scenarios/organization", ["exports", "additive-journal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.enterprise = _exports.professional = _exports.starter = void 0;
  var DEFAULTS = {
    contentLanguages: ['de', 'en', 'it'],
    defaultLanguage: 'de'
  };

  var accountApiRequests = function accountApiRequests(server, orgSlug) {
    server.get("".concat(_environment.default.APP.accountApiHost, "/api/organizations"), function (schema) {
      return schema.organizations.all();
    });
    server.get("".concat(_environment.default.APP.accountApiHost, "/api/organizations/").concat(orgSlug), function (schema) {
      return schema.organizations.findBy({
        id: orgSlug
      });
    });
    server.get("".concat(_environment.default.APP.accountApiHost, "/api/organizations/").concat(orgSlug, "/apps"), function (schema) {
      return schema.organizations.findBy({
        id: orgSlug
      });
    });
  };

  var starter = function starter(server) {
    var orgSlug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'test-org';
    server.get("".concat(server.urlPrefix, "/").concat(orgSlug), function () {
      return {
        organization: Object.assign({}, DEFAULTS, {
          planName: 'starter_yearly'
        })
      };
    });
    accountApiRequests(server, orgSlug);
  };

  _exports.starter = starter;

  var professional = function professional(server) {
    var orgSlug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'test-org';
    server.get("".concat(server.urlPrefix, "/").concat(orgSlug), function () {
      return {
        organization: Object.assign({}, DEFAULTS, {
          planName: 'professional_yearly'
        })
      };
    });
    accountApiRequests(server, orgSlug);
  };

  _exports.professional = professional;

  var enterprise = function enterprise(server) {
    var orgSlug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'test-org';
    server.get("".concat(server.urlPrefix, "/").concat(orgSlug), function () {
      return {
        organization: Object.assign({}, DEFAULTS, {
          planName: 'enterprise_yearly'
        })
      };
    });
    accountApiRequests(server, orgSlug);
  };

  _exports.enterprise = enterprise;
});