define("additive-journal/services/localization", ["exports", "@additive-apps/utils/services/localization"], function (_exports, _localization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _localization.default;
    }
  });
});