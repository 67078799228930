define("additive-journal/translations/de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "appNames": {
      "1": {
        "name": "Account",
        "presentationName": "ADDITIVE+ ACCOUNT"
      },
      "10": {
        "name": "Marketing Dashboard",
        "presentationName": "ADDITIVE+ MARKETING DASHBOARD"
      },
      "100": {
        "name": "CMS",
        "presentationName": "ADDITIVE+ CMS"
      },
      "11": {
        "name": "Anfragen",
        "presentationName": "ADDITIVE+ ANFRAGEN"
      },
      "12": {
        "name": "CRM",
        "presentationName": "ADDITIVE+ CRM"
      },
      "2": {
        "name": "Gutscheine",
        "presentationName": "ADDITIVE+ GUTSCHEINE"
      },
      "3": {
        "name": "Social Wall",
        "presentationName": "ADDITIVE+ SOCIAL WALL"
      },
      "4": {
        "name": "Inhalte",
        "presentationName": "ADDITIVE+ INHALTE"
      },
      "5": {
        "name": "Multimedia",
        "presentationName": "ADDITIVE+ MULTIMEDIA"
      },
      "50": {
        "name": "Marketing Automation",
        "presentationName": "ADDITIVE+ MARKETING AUTOMATION"
      },
      "51": {
        "name": "Marketing Insights",
        "presentationName": "ADDITIVE+ MARKETING INSIGHTS"
      },
      "6": {
        "name": "Mailer",
        "presentationName": "ADDITIVE+ MAILER"
      },
      "7": {
        "name": "Newsletter",
        "presentationName": "ADDITIVE+ NEWSLETTER"
      },
      "8": {
        "name": "Gäste Intranet",
        "presentationName": "ADDITIVE+ GÄSTE INTRANET"
      },
      "9": {
        "name": "Journal",
        "presentationName": "ADDITIVE+ JOURNAL"
      }
    },
    "breadcrumbs": {
      "demo": {
        "views": {
          "routeName": "Views",
          "simple-breadcrumb": {
            "routeName": "simple-breadcrumb"
          }
        }
      },
      "docs": {
        "components": {
          "routeName": "Components",
          "ui-breadcrumbs": {
            "routeName": "UI-Breadcrumbs"
          },
          "ui-button": {
            "routeName": "Buttons"
          },
          "ui-chart": {
            "routeName": "Charts"
          }
        },
        "routeName": "Docs"
      }
    },
    "contents": {
      "common": {
        "categories": {
          "count": "{count, plural,=0 {Keine Inhalte} =1 {1 Inhalt} other {# Inhalte}}"
        },
        "deleteCategory": {
          "description": "Die Kategorie wird dadurch unwiederruflich gelöscht und kann nicht mehr wiederhergestellt werden.<br/>Soll <span class=\"font-medium\">\"{name}\"</span> wirklich gelöscht werden?",
          "title": "Achtung!"
        },
        "newContent": {
          "post": "Erstellen Sie einen neuen Beitrag",
          "title": "Neuen Inhalt erstellen"
        },
        "properties": {
          "category": "Kategorie"
        }
      },
      "posts": {
        "description": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        "post": {
          "title": "Beitrag"
        },
        "tabs": {
          "categories": "Categories",
          "posts": "Posts"
        }
      }
    },
    "dialogService": {
      "discardChanges": {
        "discardAction": "Verwerfen",
        "message": "Sollen ungespeicherte Änderungen verworfen werden?",
        "title": "Achtung!"
      },
      "error": {
        "message": "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut!",
        "title": "Etwas ist schief gelaufen"
      },
      "noPermission": {
        "message": "Sie haben leider keine Berechtigung um diese Aktion durchzuführen. Wenden Sie sich an Ihren Administrator.",
        "title": "Fehlende Berechtigung"
      }
    },
    "global": {
      "actions": {
        "delete": "Löschen",
        "search": "Suche"
      }
    },
    "languageSelect": {
      "add": "Sprache hinzufügen",
      "ar": "Arabisch",
      "de": "Deutsch",
      "en": "Englisch",
      "fr": "Französisch",
      "it": "Italienisch",
      "nl": "Niederländisch",
      "pl": "Polnisch",
      "remove": "Sprache entfernen",
      "removeDescription": "Sind Sie sicher diese Sprache entfernen zu wollen?",
      "ru": "Russisch"
    },
    "mmAttributesDialog": {
      "alt": "Alt-Attribut",
      "dialogTitle": "Bild Attribute",
      "helpText": "Der Title-Tag eines Bildes ist der Text, der angezeigt wird, wenn man mit dem Mauszeiger auf dem Bild stehenbleibt. Das Alt-Attribut wird von Suchmaschinen verwendet um den Inhalt des Bildes zuzuordnen oder kann auch angezeigt werden, falls das Bild nicht geladen werden kann.",
      "save": "Speichern",
      "title": "Titel"
    },
    "mmCropperView": {
      "save": "Speichern"
    },
    "mmExternalSearch": {
      "duration": {
        "hours": "{count, plural, =1 {# Stunde} other {# Stunden}}",
        "minutes": "{count, plural, =1 {# Minute} other {# Minuten}}",
        "seconds": "{count, plural, =1 {# Sekunde} other {# Sekunden}}"
      },
      "insertSearchParam": "Geben Sie einen Suchbegriff ein",
      "noResults": "Keine Suchergebnisse gefunden",
      "provider": {
        "unsplash": {
          "count": "{count, plural, =1 {1 Bild} other {# Bilder}}",
          "description": "Geben Sie hier einen Suchbegriff ein, um nach Unsplash Bildern zu suchen.",
          "name": "Unsplash",
          "save": "Bilder hinzufügen",
          "title": "Unsplash Bild hinzufügen"
        },
        "youtube": {
          "description": "Geben Sie hier einen Suchbegriff ein, um das gewünschte YouTube Video hinzuzufügen oder geben Sie direkt die jeweilige URL ein.",
          "name": "YouTube",
          "save": "Video hinzufügen",
          "title": "Youtube-Video hinzufügen"
        }
      }
    },
    "mmImageTransforms": {
      "description": "Wählen Sie einen der vordefinierten Filter, um sie auf das Bild anzuwenden.",
      "editDialog": {
        "attributes": {
          "description": "Beschreibungs- und Alternativtext des Bildes festlegen",
          "title": "Bild Attribute setzen"
        },
        "imageTransform": {
          "description": "Damit können sie dem Bild einen Filter darüberlegen",
          "title": "Filter hinzufügen"
        },
        "poi": {
          "description": "Damit können sie den Fokus des Bildes definieren",
          "title": "Fokus-Point setzen"
        }
      },
      "imageLoadError": "Beim Laden des Bildes ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
      "options": {
        "brighten": "Aufhellen",
        "darken": "Abdunkeln",
        "normal": "Normal"
      },
      "preFilter": "Vordefinierte Filter",
      "title": "Bild bearbeiten"
    },
    "mmMediumItem": {
      "attribution": {
        "unsplash": "Photo by &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{authorUrl}\" class=\"white\">{author}</a>&nbsp; on &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{url}\" class=\"white\">Unsplash</a>",
        "youtube": "{title} on <u>Youtube</u>"
      },
      "error": "Die Datei konnte nicht geladen werden"
    },
    "mmPoiView": {
      "clear": "Position entfernen",
      "reload": "Nochmal versuchen",
      "save": "Speichern"
    },
    "mmResourceGallery": {
      "dropzone": "Bild hinzufügen"
    },
    "multimediaEngine": {
      "actions": {
        "add": "Hinzufügen",
        "delete": "Löschen",
        "move": "Hierher verschieben",
        "rename": "Umbenennen",
        "save": "Speichern"
      },
      "dialogs": {
        "confirmDeleteFolder": {
          "description": "Sind Sie sicher, dass Sie diesen Ordner und dessen Inhalt unwiderruflich löschen wollen?",
          "title": "Ordner löschen"
        },
        "deleteFolderIncomplete": {
          "description": "Der Ordner konnte nicht vollständig gelöscht werden, da einige Dateien aktuell verwendet werden. Insgesamt {count, plural, =1 {wurde # Datei} other {wurden # Dateien}} gelöscht.",
          "title": "Ordner konnte nicht gelöscht werden"
        }
      },
      "errors": {
        "required": "Wert darf nicht leer sein"
      },
      "folderTree": {
        "root": "Multimedia"
      },
      "folderView": {
        "bulkDelete": {
          "confirmDeleteMedium": {
            "description": "Sind Sie sicher, dass Sie {count, plural, =1 {dieses Medium} other {diese Medien}} unwiderruflich löschen wollen?",
            "title": "{count, plural, =1 {Medium} other {Medien}} löschen"
          },
          "conflict": {
            "description": "Ein oder mehrere Medien können nicht gelöscht werden, da sie zurzeit in Inhalten verwendet werden. Um diese Medien löschen zu können müssen Sie diese zuerst von allen Inhalten entfernen.",
            "title": "Nicht alle Medien sind löschbar"
          }
        },
        "delete": {
          "confirmDeleteMedium": {
            "description": "Sind Sie sicher, dass Sie dieses Medium unwiderruflich löschen wollen?",
            "title": "Medium löschen"
          },
          "conflict": {
            "description": "Das Medium kann nicht gelöscht werden, da es zurzeit in Inhalten verwendet wird. Um dieses Medium löschen zu können müssen Sie es zuerst von allen Inhalten entfernen.",
            "title": "Medium ist in Verwendung"
          }
        },
        "folder": "Ordner",
        "media": "Dateien",
        "new": {
          "externalSearch": {
            "title": "Externe Medien"
          },
          "folder": {
            "title": "Ordner erstellen"
          },
          "title": "Hinzufügen",
          "upload": {
            "title": "Datei hochladen"
          }
        },
        "uploading": {
          "description": "Während ein Hochladevorgang läuft kann nichts weiteres hochgeladen werden.",
          "title": "Hochladevorgang läuft."
        }
      },
      "mmFileUpload": {
        "maxConcurrentFilesUploaded": {
          "description": "Sie können nur maximal {count} Dateien in einem Hochladevorgang hochladen. Sie können die Restlichen nach dem der aktuelle Hochladevorgang beendet ist hochladen.",
          "title": "Limit für gleichzeitiges Hochladen erreicht"
        },
        "maxSizeDialog": {
          "description": "Eine oder mehrere Dateien welche sie hochladen möchten überschreiten die zulässige Maximalgröße von 10MB, diese werden nicht hochgeladen.",
          "title": "Überschreitung der Maximalgröße"
        }
      },
      "mmFileUploadProgress": {
        "aborted": {
          "title": "Hochladen abgebrochen"
        },
        "errorDialog": {
          "description": "{failed} von {count} konnten nicht hochgeladen werden, da sie entweder nicht unterstützt werden oder ein unerwarteter Fehler aufgetreten ist.",
          "title": "Uploadfehler"
        },
        "progress": {
          "description": "Dateien werden hochgeladen",
          "title": "Hochladen"
        },
        "success": {
          "title": "Hochladen erfolgreich"
        }
      },
      "mmFilter": {
        "mr": "Größer als",
        "noMatches": "Ihre Filterung ergab keine Treffer",
        "searchPlaceholder": "Suchen Sie z.B. nach \"Wellness\"",
        "type": {
          "title": "Dateityp",
          "types": {
            "file": "Datei",
            "image": "Bild",
            "video": "Video"
          }
        }
      },
      "mmFolderCreateEdit": {
        "name": "Name",
        "title": {
          "create": "Ordner erstellen",
          "edit": "Ordner umbenennen"
        }
      },
      "toasts": {
        "folderDeleteSuccess": {
          "description": "Der Ordner wurde erfolgreich gelöscht",
          "title": "Ordner gelöscht"
        },
        "success": {
          "title": "Aktion erfolgreich durchgeführt"
        }
      },
      "warnings": {
        "maxCount": "Es {count, plural, =1 {darf maximal # Element} other {dürfen maximal # Elemente}} ausgewählt werden.",
        "recommendedSize": {
          "action": "Trotzdem hinzufügen",
          "message": "In Ihrer Auswahl befinden sich Dateien, welche nicht der empfohlenen Mindestgröße entsprechen.",
          "title": "Achtung"
        }
      }
    },
    "pageNotFound": {
      "button": "Zur Startseite",
      "content": "Leider konnte die gesuchte Seite nicht gefunden werden! <br>Entweder ist die URL nicht korrekt oder die Seite wurde entfernt bzw. umbenannt.",
      "title": "Seite wurde nicht gefunden!"
    },
    "routes": {
      "authors": {
        "title": "Autoren"
      },
      "dashboard": {
        "title": "Übersicht"
      },
      "posts": {
        "title": "Beiträge"
      },
      "settings": {
        "title": "Einstellungen"
      },
      "topics": {
        "title": "Themen"
      },
      "widgets": {
        "title": "Widgets"
      }
    },
    "select": {
      "empty": "Keine Optionen gefunden",
      "noResults": "Keine Ergebnisse gefunden",
      "searchPlaceholder": "Suche"
    },
    "selectContainer": {
      "items": "{count, plural, =1 {# Element} other {# Elemente}} ausgewählt"
    },
    "toast": {
      "success": "Aktion erfolgreich durchgeführt",
      "unexpectedError": "Ein unerwarteter Fehler ist aufgetreten"
    },
    "uiActions": {
      "back": "Zurück",
      "export": "Exportieren",
      "info": "Informationen",
      "menu": "Menü",
      "more": "Mehr",
      "save": "Speichern",
      "search": "Suchen"
    },
    "uiAppCollection": {
      "daysLeft": "noch {days} Tage",
      "hideApps": "Apps ausblenden",
      "new": "Neu",
      "showAllMyApps": "Alle meine Apps anzeigen",
      "showMoreApps": "Weitere Apps entdecken"
    },
    "uiCategoryCard": {
      "contentCountError": "Diese Kategorie kann nicht gelöscht werden, da sie noch Inhalte enthält.",
      "titleError": "Achtung!"
    },
    "uiCollapsibleTable": {
      "collapsibleRowDescription": "{count, plural, =1 {# Unterkategorie} other {# Unterkategorien}}"
    },
    "uiColorComposition": {
      "dialog": {
        "preview": "Vorschau",
        "save": "Speichern"
      }
    },
    "uiContentCount": {
      "count": "{count, plural, =1 {# Eintrag} other {# Einträge}}",
      "filterCount": "{count, plural, =1 {# Eintrag} other {# Einträge}} gefunden",
      "pages": "Seite <span class=\"primary ph05\">{page}</span> / <span class=\"pl05\">{total}</span>"
    },
    "uiDateFilter": {
      "label": "Betrachtungszeitraum"
    },
    "uiDatePicker": {
      "confirm": "Bestätigen",
      "reset": "Zurücksetzen",
      "weekDaysShort": {
        "Fri": "Fr",
        "Mon": "Mo",
        "Sat": "Sa",
        "Sun": "So",
        "Thu": "Do",
        "Tue": "Di",
        "Wed": "Mi"
      }
    },
    "uiDatetimePicker": {
      "date": "Datum",
      "endDate": "Enddatum",
      "endTime": "Enduhrzeit",
      "error": {
        "beforeToday": "Vergangenes Datum",
        "false": "Ungültiges Datum"
      },
      "startDate": "Startdatum",
      "startTime": "Startuhrzeit",
      "time": "Uhrzeit"
    },
    "uiDiscardChanges": {
      "discardAction": "Verwerfen",
      "message": "Sollen ungespeicherte Änderungen verworfen werden?",
      "title": "Achtung!"
    },
    "uiEditor": {
      "actions": {
        "bold": "Fett",
        "bulletList": "Aufzählungsliste",
        "code": "Code",
        "headings": {
          "h1": "Überschrift 1",
          "h2": "Überschrift 2",
          "h3": "Überschrift 3",
          "h4": "Überschrift 4",
          "p": "Normal"
        },
        "headingsShort": {
          "h1": "H1",
          "h2": "H2",
          "h3": "H3",
          "h4": "H4",
          "p": "P"
        },
        "horizontalRule": "Trennlinie",
        "italic": "Kursiv",
        "link": "Link",
        "numberedList": "Numerierte Liste",
        "quote": "Zitat",
        "underline": "Unterstrichen"
      },
      "urlDialog": {
        "openInNewTab": "In neuem Tab öffnen",
        "save": "Speichern",
        "text": "Text",
        "type": {
          "name": "Link-Typ",
          "options": {
            "email": "E-Mail",
            "tel": "Telefon",
            "weblink": "Weblink"
          }
        },
        "url": "URL",
        "urlNotValid": {
          "email": "Diese E-Mail-Adresse ist nicht gültig",
          "tel": "Diese Telefonnummer ist nicht gültig",
          "weblink": "Diese URL ist nicht gültig"
        }
      }
    },
    "uiFilterInputs": {
      "buttonText": "Filtern",
      "daterange": {
        "end": "bis",
        "start": "von"
      }
    },
    "uiFormControl": {
      "recommendedLengthWarning": "Wert überschreitet die empfohlene Länge von {recommendedLength} Zeichen"
    },
    "uiFormDialog": {
      "save": "Speichern"
    },
    "uiListView": {
      "bulkDelete": "{count, plural, =1 {Ein Element} other {# Elemente}} gelöscht",
      "common": {
        "bulkDelete": {
          "action": "Alle löschen",
          "description": "Die ausgewählten Inhalte werden dadurch unwiederruflich gelöscht und können nicht mehr wiederhergestellt werden.<br/>Sollen die ausgewählten Inhalte wirklich gelöscht werden?"
        },
        "deleteContent": {
          "description": "Der Inhalt wird dadurch unwiederruflich gelöscht und kann nicht mehr wiederhergestellt werden.<br/>Soll <span class=\"font-medium\">\"{name}\"</span> wirklich gelöscht werden?",
          "title": "Achtung!"
        }
      },
      "delete": "Löschen",
      "empty": "Es gibt noch keine Inhalte.<br/>Klicken Sie auf den untenstehenden Button um einen Inhalt zu erstellen.",
      "error": "Ein unerwarteter Fehler ist aufgetreten.",
      "errors": {
        "vouchers_left": {
          "message": "Dieser Inhalt kann nicht gelöscht werden, da er in einem Gutschein verwendet wird.",
          "title": "Achtung!"
        }
      },
      "noMatch": "Ihre Filterung ergab keine Treffer.",
      "properties": {
        "published": "öffentlich",
        "unpublished": "nicht öffentlich"
      }
    },
    "uiMonthDayPicker": {
      "day": "Tag",
      "month": "Monat"
    },
    "uiNavigationBarFilterabel": {
      "dynamicFilters": {
        "buttonText": "Weitere Filter",
        "error": {
          "retryButtonText": "Es ist ein Fehler aufgetreten! Jetzt neu versuchen"
        },
        "navigationDrawer": {
          "title": "Weitere Filter"
        }
      },
      "searchInputDefaultPlaceholder": "Durchsuchen"
    },
    "uiOnboarding": {
      "actions": {
        "back": "Zurück",
        "endOnboarding": "Jetzt loslegen",
        "next": "Weiter",
        "skip": "Überspringen"
      }
    },
    "uiPublishResource": {
      "description": "Wählen Sie hier aus welche Sprachen Sie veröffentlichen möchten. Zudem können Sie den Rahmen der Veröffentlichung zeitlich einschränken.",
      "languages": "Sprachen",
      "limitTime": "Veröffentlichungszeitraum einschränken",
      "save": "Speichern",
      "settings": "Einstellungen",
      "title": "Veröffentlichen"
    },
    "uiTable": {
      "empty": "Keine Daten vorhanden",
      "error": "Ein Fehler ist aufgetreten",
      "retry": "Nochmal versuchen"
    },
    "uiTags": {
      "error": "Dieser Wert wurde bereits hinzugefügt",
      "placeholder": "Tag hinzufügen",
      "tags": "Tags"
    },
    "uiTimepicker": {
      "errorMessage": "Ungültige Zeitangabe",
      "timeUnit": "Uhr"
    },
    "uiToast": {
      "success": "Aktion erfolgreich durchgeführt",
      "unexpectedError": "Ein unerwarteter Fehler ist aufgetreten"
    }
  };
  _exports.default = _default;
});