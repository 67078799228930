define("additive-journal/templates/instance/posts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jXXhf/6M",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-list-view\",[],[[\"@filterKey\",\"@modelName\",\"@detailRoute\",\"@hideImage\",\"@descriptionProperty\"],[\"posts\",\"post\",\"instance.posts.index.post.index\",false,\"topic.name\"]],null],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "additive-journal/templates/instance/posts/index.hbs"
    }
  });

  _exports.default = _default;
});