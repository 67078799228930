define("additive-journal/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "appNames": {
      "1": {
        "name": "Account",
        "presentationName": "ADDITIVE+ ACCOUNT"
      },
      "10": {
        "name": "Marketing Dashboard",
        "presentationName": "ADDITIVE+ MARKETING DASHBOARD"
      },
      "100": {
        "name": "CMS",
        "presentationName": "ADDITIVE+ CMS"
      },
      "11": {
        "name": "Requests",
        "presentationName": "ADDITIVE+ ANFRAGEN"
      },
      "12": {
        "name": "CRM",
        "presentationName": "ADDITIVE+ CRM"
      },
      "2": {
        "name": "Vouchers",
        "presentationName": "ADDITIVE+ GUTSCHEINE"
      },
      "3": {
        "name": "Social Wall",
        "presentationName": "ADDITIVE+ SOCIAL WALL"
      },
      "4": {
        "name": "Content",
        "presentationName": "ADDITIVE+ INHALTE"
      },
      "5": {
        "name": "Multimedia",
        "presentationName": "ADDITIVE+ MULTIMEDIA"
      },
      "50": {
        "name": "Marketing Automation",
        "presentationName": "ADDITIVE+ MARKETING AUTOMATION"
      },
      "51": {
        "name": "Marketing Insights",
        "presentationName": "ADDITIVE+ MARKETING INSIGHTS"
      },
      "6": {
        "name": "Mailer",
        "presentationName": "ADDITIVE+ MAILER"
      },
      "7": {
        "name": "Newsletter",
        "presentationName": "ADDITIVE+ NEWSLETTER"
      },
      "8": {
        "name": "Guest Intranet",
        "presentationName": "ADDITIVE+ GÄSTE INTRANET"
      },
      "9": {
        "name": "Journal",
        "presentationName": "ADDITIVE+ JOURNAL"
      }
    },
    "breadcrumbs": {
      "demo": {
        "views": {
          "routeName": "Views",
          "simple-breadcrumb": {
            "routeName": "simple-breadcrumb"
          }
        }
      },
      "docs": {
        "components": {
          "routeName": "Components",
          "ui-breadcrumbs": {
            "routeName": "UI-Breadcrumbs"
          },
          "ui-button": {
            "routeName": "Buttons"
          },
          "ui-chart": {
            "routeName": "Charts"
          }
        },
        "routeName": "Docs"
      }
    },
    "dialogService": {
      "discardChanges": {
        "discardAction": "Discard",
        "message": "Do you want to discard the unsaved changes?",
        "title": "Attention!"
      },
      "error": {
        "message": "An unexpected error has occurred. Please try again later.",
        "title": "Something went wrong"
      },
      "noPermission": {
        "message": "You do not have permission to perform this action. Please contact your administrator.",
        "title": "Missing permission"
      }
    },
    "languageSelect": {
      "add": "Add language",
      "ar": "Arabic",
      "de": "German",
      "en": "English",
      "fr": "French",
      "it": "Italian",
      "nl": "Dutch",
      "pl": "Polish",
      "remove": "Remove language",
      "removeDescription": "Are you sure you want to remove this language?",
      "ru": "Russian"
    },
    "mmAttributesDialog": {
      "alt": "Alt attribute",
      "dialogTitle": "Image attribute",
      "helpText": "The title tag of an image is the text that is displayed when the mouse moves over the image. The alt attribute is used by search engines to assign the content of the image. It can also be displayed if the image cannot be loaded.",
      "save": "Save",
      "title": "Title"
    },
    "mmCropperView": {
      "save": "Save"
    },
    "mmExternalSearch": {
      "duration": {
        "hours": "{count, plural, =1 {# hour} other {# hours}}",
        "minutes": "{count, plural, =1 {# minute} other {# minutes}}",
        "seconds": "{count, plural, =1 {# second} other {# seconds}}"
      },
      "insertSearchParam": "Enter a search term",
      "noResults": "No results found",
      "provider": {
        "unsplash": {
          "count": "{count, plural, =1 {1 image} other {# images}}",
          "description": "Enter a search term here to find images on Unsplash.",
          "name": "Unsplash",
          "save": "Add images",
          "title": "Add Unsplash images"
        },
        "youtube": {
          "description": "Enter a search term here to find the desired YouTube video or simply enter the corresponding URL.",
          "name": "YouTube",
          "save": "Add video",
          "title": "Add YouTube video"
        }
      }
    },
    "mmImageTransforms": {
      "description": "Select one of the predefined filters to apply it to the image.",
      "editDialog": {
        "attributes": {
          "description": "Define the description text and alternative text for the image",
          "title": "Set image attributes"
        },
        "imageTransform": {
          "description": "Use this to apply a filter to the image",
          "title": "Add filter"
        },
        "poi": {
          "description": "This allows you to define the focus of the image",
          "title": "Set focal point"
        }
      },
      "imageLoadError": "An error occurred while loading the image, please try again later.",
      "options": {
        "brighten": "Brighten",
        "darken": "Darken",
        "normal": "Normal"
      },
      "preFilter": "Predefined filter",
      "title": "Edit image"
    },
    "mmMediumItem": {
      "attribution": {
        "unsplash": "Photo by &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{authorUrl}\" class=\"white\">{author}</a>&nbsp; on &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{url}\" class=\"white\">Unsplash</a>",
        "youtube": "{title} on <u>Youtube</u>"
      },
      "error": "The file could not be loaded"
    },
    "mmPoiView": {
      "clear": "Remove position",
      "reload": "Try again",
      "save": "Save"
    },
    "mmResourceGallery": {
      "dropzone": "Add image"
    },
    "multimediaEngine": {
      "actions": {
        "add": "Add",
        "delete": "Delete",
        "move": "Move here",
        "rename": "Rename",
        "save": "Save"
      },
      "dialogs": {
        "confirmDeleteFolder": {
          "description": "Are you sure you want to delete this folder and its contents irrevocably?",
          "title": "Delete folder"
        },
        "deleteFolderIncomplete": {
          "description": "The folder could not be deleted completely because some of its files are currently in use. In total {count, plural, =1 {# File} other {# Files}} were deleted.",
          "title": "Folder could not be deleted"
        }
      },
      "errors": {
        "required": "Value can't be empty"
      },
      "folderTree": {
        "root": "Multimedia"
      },
      "folderView": {
        "bulkDelete": {
          "confirmDeleteMedium": {
            "description": "Are you sure you want to delete {count, plural, =1 {this medium} other {this media}}",
            "title": "Delete {count, plural, =1 {medium} other {media}}"
          },
          "conflict": {
            "description": "The media cannot be deleted, as it is currently used in a content. To delete this media, you must first remove it from all contents.",
            "title": "Medium is in use"
          }
        },
        "delete": {
          "confirmDeleteMedium": {
            "description": "Are you sure you want to delete this medium irrevocably?",
            "title": "Delete medium"
          },
          "conflict": {
            "description": "The medium cannot be deleted, as it is currently used in a content. To delete this medium, you must first remove it from all contents.",
            "title": "Medium is in use"
          }
        },
        "folder": "Folder",
        "media": "Files",
        "new": {
          "externalSearch": {
            "title": "External media"
          },
          "folder": {
            "title": "Create folder"
          },
          "title": "Add",
          "upload": {
            "title": "Upload file"
          }
        },
        "uploading": {
          "description": "While an upload is running, nothing else can be uploaded.",
          "title": "Uploading..."
        }
      },
      "mmFileUpload": {
        "maxConcurrentFilesUploaded": {
          "description": "You can upload at most {count} files during a single upload. You can upload the rest after the current upload process is finished.",
          "title": "Limit for simultaneous upload reached"
        },
        "maxSizeDialog": {
          "description": "One or more files you want to upload exceed the maximum size of 10MB, they will not be uploaded.",
          "title": "Exceeding maximum size"
        }
      },
      "mmFileUploadProgress": {
        "aborted": {
          "title": "Uploading cancelled"
        },
        "errorDialog": {
          "description": "{failed} of {count} could not be uploaded, because they are either not supported or an unexpected error has occurred.",
          "title": "Upload error"
        },
        "progress": {
          "description": "Files are uploaded",
          "title": "Uploading"
        },
        "success": {
          "title": "Uploading successful"
        }
      },
      "mmFilter": {
        "mr": "Greater than",
        "noMatches": "Your filtering did not yield any resuts",
        "searchPlaceholder": "Search...",
        "type": {
          "title": "File type",
          "types": {
            "file": "File",
            "image": "Image",
            "video": "Video"
          }
        }
      },
      "mmFolderCreateEdit": {
        "name": "Name",
        "title": {
          "create": "Create folder",
          "edit": "Rename folder"
        }
      },
      "toasts": {
        "folderDeleteSuccess": {
          "description": "The folder was successfully deleted",
          "title": "Folder deleted"
        },
        "success": {
          "title": "Action performed successfully"
        }
      },
      "warnings": {
        "maxCount": "Only {count, plural, =1 {# element} other {# elements}} can be selected.",
        "recommendedSize": {
          "action": "Add anyway",
          "message": "In your selection there are files that do not meet the recommended minimum size.",
          "title": "Attention"
        }
      }
    },
    "pageNotFound": {
      "button": "Homepage",
      "content": "The page you were looking for could not be found!<br>The URL may be incorrect or the page may have been removed or renamed.",
      "title": "Page not found!"
    },
    "routes": {
      "authors": {
        "title": "Authors"
      },
      "dashboard": {
        "title": "Dashboard"
      },
      "posts": {
        "title": "Posts"
      },
      "settings": {
        "title": "Settings"
      },
      "topics": {
        "title": "Topics"
      },
      "widgets": {
        "title": "Widgets"
      }
    },
    "select": {
      "empty": "No option found",
      "noResults": "No results found",
      "searchPlaceholder": "Search"
    },
    "selectContainer": {
      "items": "{count, plural, =1 {# element} other {# elements}} selected"
    },
    "uiActions": {
      "back": "Back",
      "export": "Export",
      "info": "Informations",
      "menu": "Menu",
      "more": "More",
      "save": "Save",
      "search": "Search"
    },
    "uiAppCollection": {
      "daysLeft": "{days} days left",
      "hideApps": "Hide apps",
      "new": "New",
      "showAllMyApps": "Show all my apps",
      "showMoreApps": "Discover other apps"
    },
    "uiCategoryCard": {
      "contentCountError": "This category cannot be deleted, because it still contains content.",
      "titleError": "Attention!"
    },
    "uiCollapsibleTable": {
      "collapsibleRowDescription": "{count, plural, =1 {# subcategory} other {# subcategories}}"
    },
    "uiColorComposition": {
      "dialog": {
        "preview": "Preview",
        "save": "Save"
      }
    },
    "uiContentCount": {
      "count": "{count, plural, =1 {# entry} other {# entries}}",
      "filterCount": "{count, plural, =1 {# entry} other {# entries}} found",
      "pages": "Page <span class=\"primary ph05\">{page}</span> / <span class=\"pl05\">{total}</span>"
    },
    "uiDateFilter": {
      "label": "Observation period"
    },
    "uiDatePicker": {
      "confirm": "Confirm",
      "reset": "Reset",
      "weekDaysShort": {
        "Fri": "Fri",
        "Mon": "Mon",
        "Sat": "Sat",
        "Sun": "Sun",
        "Thu": "Thu",
        "Tue": "Tue",
        "Wed": "Wed"
      }
    },
    "uiDatetimePicker": {
      "date": "Date",
      "endDate": "End date",
      "endTime": "End time",
      "error": {
        "beforeToday": "Past date",
        "false": "Invalid date"
      },
      "startDate": "Start date",
      "startTime": "Start time",
      "time": "Time"
    },
    "uiDiscardChanges": {
      "discardAction": "Discard",
      "message": "Do you want to discard the unsaved changes?",
      "title": "Attention!"
    },
    "uiEditor": {
      "actions": {
        "bold": "Bold",
        "bulletList": "List",
        "code": "Code",
        "headings": {
          "h1": "Heading 1",
          "h2": "Heading 2",
          "h3": "Heading 3",
          "h4": "Heading 4",
          "p": "Normal"
        },
        "headingsShort": {
          "h1": "H1",
          "h2": "H2",
          "h3": "H3",
          "h4": "H4",
          "p": "P"
        },
        "horizontalRule": "Horizontal line",
        "italic": "Italic",
        "link": "Link",
        "numberedList": "Numbered list",
        "quote": "Quote",
        "underline": "Underline"
      },
      "urlDialog": {
        "openInNewTab": "Open in new tab",
        "save": "Save",
        "text": "Text",
        "type": {
          "name": "Link type",
          "options": {
            "email": "Email",
            "tel": "Phone",
            "weblink": "Web link"
          }
        },
        "url": "URL",
        "urlNotValid": {
          "email": "This email address is not valid",
          "tel": "This phone number is not valid",
          "weblink": "This URL is not valid"
        }
      }
    },
    "uiFilterInputs": {
      "buttonText": "Filter",
      "daterange": {
        "end": "until",
        "start": "from"
      }
    },
    "uiFormControl": {
      "recommendedLengthWarning": "Value exceeds the recommended length of {maxLength} characters"
    },
    "uiFormDialog": {
      "save": "Save"
    },
    "uiListView": {
      "bulkDelete": "{count, plural, =1 {One element} other {# elements}} deleted",
      "common": {
        "bulkDelete": {
          "action": "Delete all",
          "description": "The selected contents will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete the selected contents?"
        },
        "deleteContent": {
          "description": "The content will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete <span class=\"font-medium\">\"{name}\"</span>?",
          "title": "Attention!"
        }
      },
      "delete": "Delete",
      "empty": "There are no existing contents.<br/>Click the button below to create a new content.",
      "error": "An unexpected error occurred.",
      "errors": {
        "vouchers_left": {
          "message": "This category cannot be deleted since it is used in a voucher.",
          "title": "Attention!"
        }
      },
      "noMatch": "Your search did not return any results.",
      "properties": {
        "published": "published",
        "unpublished": "unpublished"
      }
    },
    "uiMonthDayPicker": {
      "day": "Day",
      "month": "Month"
    },
    "uiNavigationBarFilterabel": {
      "dynamicFilters": {
        "buttonText": "More filters",
        "error": {
          "retryButtonText": "An error has occurred! Try again now"
        },
        "navigationDrawer": {
          "title": "More filters"
        }
      },
      "searchInputDefaultPlaceholder": "Search"
    },
    "uiOnboarding": {
      "actions": {
        "back": "Back",
        "endOnboarding": "Get started now",
        "next": "Next",
        "skip": "Skip"
      }
    },
    "uiPublishResource": {
      "description": "Choose which language you would like to publish. You can also limit the time frame for the publication.",
      "languages": "Languages",
      "limitTime": "Limit the time frame",
      "save": "Save",
      "settings": "Settings",
      "title": "Publish"
    },
    "uiTable": {
      "empty": "No data available",
      "error": "An error has occurred",
      "retry": "Try again"
    },
    "uiTags": {
      "error": "This value has already been added",
      "placeholder": "Add tag",
      "tags": "Tags"
    },
    "uiTimepicker": {
      "errorMessage": "Invalid time value",
      "timeUnit": ""
    },
    "uiToast": {
      "success": "Action completed successfully",
      "unexpectedError": "An unexpected error has occurred"
    }
  };
  _exports.default = _default;
});