define("additive-journal/mirage/factories/user", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* TODO: Add me to an addon */
  var _default = _emberCliMirage.Factory.extend({
    email: _faker.default.internet.email(),
    fullName: function fullName() {
      return "".concat(_faker.default.name.firstName(), " ").concat(_faker.default.name.lastName());
    },
    isAdditive: true,
    isReseller: true,
    isSuperAdmin: false,
    isSupport: false,
    role: 'member',
    language: 'de'
  });

  _exports.default = _default;
});