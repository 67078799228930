define("additive-journal/helpers/access-guard", ["exports", "@additive-apps/auth/helpers/access-guard"], function (_exports, _accessGuard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _accessGuard.default;
    }
  });
  Object.defineProperty(_exports, "accessGuard", {
    enumerable: true,
    get: function get() {
      return _accessGuard.accessGuard;
    }
  });
});