define("additive-journal/components/create-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! template-lint-disable no-action }}
  <UiModal
    @title={{t "contents.common.newContent.title"}}
    @modalClassNames="ac-create-dialog"
    @onClose={{action this.onClose}} as |m|
  >
    <m.header />
    <m.content @classNames="pb0" as |c|>
      <c.body>
        <UiItemGroup @class="w-100 pv0">
          {{yield
            (hash
              item=(component "ui-item" class="h4 pa1 ma05 font-medium bg-white")
              goTo=(action "goTo")
              delimiter=(component
                "ui-blank-template" tagName="div" classNames="bb b--black-10 mh1 pv05"
              )
            )
          }}
        </UiItemGroup>
      </c.body>
    </m.content>
  </UiModal>
  */
  {"id":"qSaSGwin","block":"{\"symbols\":[\"m\",\"c\",\"&default\"],\"statements\":[[8,\"ui-modal\",[],[[\"@title\",\"@modalClassNames\",\"@onClose\"],[[30,[36,0],[\"contents.common.newContent.title\"],null],\"ac-create-dialog\",[30,[36,1],[[32,0],[32,0,[\"onClose\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],null],[2,\"\\n  \"],[8,[32,1,[\"content\"]],[],[[\"@classNames\"],[\"pb0\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"ui-item-group\",[],[[\"@class\"],[\"w-100 pv0\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[18,3,[[30,[36,3],null,[[\"item\",\"goTo\",\"delimiter\"],[[30,[36,2],[\"ui-item\"],[[\"class\"],[\"h4 pa1 ma05 font-medium bg-white\"]]],[30,[36,1],[[32,0],\"goTo\"],null],[30,[36,2],[\"ui-blank-template\"],[[\"tagName\",\"classNames\"],[\"div\",\"bb b--black-10 mh1 pv05\"]]]]]]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"component\",\"hash\"]}","meta":{"moduleName":"additive-journal/components/create-dialog.hbs"}});

  /**
   * The dialog used to choose between creating a new content or category
   *
   * @class create-dialog
   */
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: '',
    onClose: function onClose() {},
    actions: {
      goTo: function goTo(path) {
        this.router.transitionTo(path);
        this.onClose();
      }
    }
  }));

  _exports.default = _default;
});