define("additive-journal/controllers/application", ["exports", "additive-journal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var navDrawerStateKey = 'ui-navigation-drawer'; // add instance-routes here

  var instanceRoutes = [{
    path: 'instance.dashboard',
    i18nKey: 'routes.dashboard.title',
    svg: 'overview'
  }, {
    path: 'instance.posts',
    i18nKey: 'routes.posts.title',
    svg: 'post'
  }, {
    path: 'instance.authors',
    i18nKey: 'routes.authors.title',
    svg: 'people'
  }, {
    path: 'instance.topics',
    i18nKey: 'routes.topics.title',
    svg: 'grid'
  }, {
    path: 'instance.widgets',
    i18nKey: 'routes.widgets.title',
    svg: 'style'
  }, {
    path: 'instance.settings',
    i18nKey: 'routes.settings.title',
    svg: 'settings'
  }];

  var _default = Ember.Controller.extend({
    authenticatedFetch: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    uiAppSettings: Ember.inject.service(),
    uiState: Ember.inject.service(),
    queryParams: ['onboarding'],
    onboarding: null,
    navDrawerState: null,
    user: Ember.computed.alias('currentUser.user'),
    currentOrganization: Ember.computed.alias('currentUser.currentOrganization'),
    userRole: Ember.computed.alias('currentUser.organizationUser.role'),
    isAdmin: Ember.computed.alias('currentUser.isAdmin'),
    instanceRoutes: Ember.computed('isAdmin', {
      get: function get() {
        var isAdmin = this.isAdmin;
        return instanceRoutes.filter(function (app) {
          return app.requiredRole === 'admin' ? isAdmin : true;
        });
      }
    }),

    /**
     * the content appId
     *
     * @property appId
     * @type {Number}
     * @default undefined
     */
    appId: undefined,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'appId', _environment.default.APP.appId);
    },
    actions: {
      closeNavigationDrawer: function closeNavigationDrawer() {
        this.uiState.getState(navDrawerStateKey).close();
      },
      transitionToOrganizationRoute: function transitionToOrganizationRoute(organization) {
        this.transitionToRoute('instance', organization.id);
        Ember.set(this, 'currentUser.currentOrganization', organization);
        Ember.set(this, 'currentUser.user', null);
        this.store.unloadAll('app'); // mm related models

        this.store.unloadAll('folder');
        this.store.unloadAll('medium');
        this.uiAppSettings.reset();
        this.send('closeNavigationDrawer');
      },
      logout: function logout() {
        window.location.href = "".concat(_environment.default.APP.accountHost, "/logout");
      },
      finishOnboarding: function finishOnboarding() {
        Ember.set(this, 'onboarding', null);
      }
    }
  });

  _exports.default = _default;
});