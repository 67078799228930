define("additive-journal/models/post", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend((_dec = Ember.computed('media.[]', 'hasImage'), (_obj = {
    title: (0, _model.attr)('string'),
    text: (0, _model.attr)('string'),
    name: Ember.computed.alias('title'),
    hasImage: Ember.computed.gt('media.length', 0),
    author: (0, _model.belongsTo)('author', {
      async: true
    }),
    topic: (0, _model.belongsTo)('topic', {
      async: true
    }),
    allowComments: (0, _model.attr)('boolean'),
    publishedAt: (0, _model.attr)('date'),
    media: (0, _model.attr)(),
    tags: (0, _model.attr)(),
    comments: (0, _model.attr)(),

    get primaryImage() {
      if (!this.hasImage) {
        return {
          url: ''
        };
      }

      var img_url = this.media[0].url;
      return {
        url: img_url
      };
    }

  }, (_applyDecoratedDescriptor(_obj, "primaryImage", [_dec], Object.getOwnPropertyDescriptor(_obj, "primaryImage"), _obj)), _obj)));

  _exports.default = _default;
});