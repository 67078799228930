define("additive-journal/serializers/application", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    /**
     * can be overwritten to prevent the serializer from
     * removing a property with a null value
     *
     * @property nullableProperties
     * @type {[String]}
     */
    nullableProperties: null,
    serialize: function serialize(snapshot, options) {
      var _this = this;

      var json = this._super(snapshot, options);

      Object.keys(json).forEach(function (key) {
        return Ember.isNone(json[key]) && (_this.nullableProperties || []).indexOf(key) < 0 && delete json[key];
      });
      return json;
    },
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      Object.assign(data, this.serialize(record, options));
    },

    /**
     * adds the nullable properties to the payload, with value null
     */
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var _this2 = this;

      var modelName = Object.keys(payload)[0];
      var data = Ember.isArray(payload[modelName]) ? payload[modelName] : [payload[modelName]];
      this.nullableProperties && data.forEach(function (model) {
        _this2.nullableProperties.forEach(function (property) {
          if (!model[property]) {
            model[property] = null;
          }
        });
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});