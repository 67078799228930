define("additive-journal/templates/instance/authors/author", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K9HTDVXH",
    "block": "{\"symbols\":[\"view\"],\"statements\":[[8,\"ui-content-views\",[],[[\"@title\"],[[32,0,[\"model\",\"name\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"navbar\"]],[],[[\"@backAction\"],[[30,[36,0],[[32,0,[\"back\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex flex-grow-1\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"intro\"]],[],[[\"@class\",\"@title\",\"@size\",\"@isMultiline\"],[\"mw6\",[32,0,[\"model\",\"name\"]],\"xl\",false]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[],[[\"@classNames\"],[\"pt4\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "additive-journal/templates/instance/authors/author.hbs"
    }
  });

  _exports.default = _default;
});